<template>
  <div class="main-card">
    <div class="cover-detail">
      <h2><slot name="title1"></slot> | <slot name="title2"></slot></h2>
      <p><slot name="sub-title1"></slot> | <slot name="sub-title2"></slot></p>
    </div>
    <div class="content">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
.main-card {
  .cover-detail {
    width: 100%;
    min-height: 145px;
    background-color: var(--kit-color-primary);
    border-radius: 10px;
    padding: 20px 40px;
    color: #ffffff;
  }

  h2,
  p {
    color: #ffffff;
  }

  .content {
    width: 100%;
    min-height: 20vh;
    background-color: white;
    padding: 40px;
    z-index: 11;
    margin-top: -10px;
    border-radius: 10px;
    box-shadow: 0 0 10px #0419301a;
    margin-left: 1px;
  }
}
</style>
